.business-card {
  height: 150px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}

.business-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.search-wrap {
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 600px;
  justify-content: end;
}
.support-select-box {
  width: 200px;
}

.support-select-box .form-select {
  border-radius: 50px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #2d3748;
  height: 40px;
}

.support-bussiness-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.support-bussiness-head h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #09101d;
}

.business-content-card {
  padding-left: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 4px; */
}
.business-card-content-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.card-content-description {
  padding-left: 15px;
  padding-right: 10px;
}
.business-card-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #09101d;
  line-height: 17px;
  display: flex;
  flex-wrap: wrap;
  height: 17px;
  overflow-y: auto;
  overflow-x: hidden;
}
.business-card-subtitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
  line-height: 17px;
  height: 17px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
}
.business-short-details {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #080ccb;
  display: flex;
  flex-wrap: wrap;
  height: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
}
.card-content-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #718096;
  height: 30px;
  overflow-y: auto;
  overflow-x: hidden;
}

.drop-down-filter {
  background-color: white;
}
.no-data-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
}
.business-image-div {
  background: #bdcde3;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.business-image {
  width: 120px;
  min-width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 20px;
  background: #bdcde3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-file-wrap {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
}
.documents-text {
  /* padding-left: 15px;
  padding-right: 10px; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #080ccb;
}

.no-documents-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #718096;
  /* padding-left: 15px;
  padding-right: 10px; */
}

@media screen and (max-width: 865px) {
  .support-bussiness-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 10px; */
  }
  .search-wrap {
    width: 600px;
    align-items: center;
    justify-content: center;
    /* padding-top: 10px; */
  }
}
@media screen and (max-width: 600px) {
  .business-image-class {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 580px) {
  .search-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    align-items: center;
    /* padding-top: 10px; */
  }
}
/* ---------------responsive code------------------- */
.business-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  gap: 1.5rem;
}
@media screen and (max-width: 1180px) {
  .business-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 750px) {
  .business-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
