nav {
  position: relative;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}
.activeNavClass {
  color: var(--navLink-active-color);
}
.activeNavClass:hover {
  color: var(--navLink-active-color);
}
.navLink-color {
  color: var(--navLink-color);
}
.bellIcon-div {
  width: 40px;
  height: 40px;
  border: 1px solid #e2e8f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.profileIcon-div {
  background-color: var(--navLink-active-color);
}
/* -------------------------------------------------- */
.navBar-div {
  display: flex;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  /* gap: 1rem; */
  font-family: 'Raleway';
  font-style: normal;
}
.navBar-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(8, 12, 203, 0.5);
}
.navBar-brand {
  width: 13rem;
}
.navBar-brand-image {
  /* max-width: 100%;
  max-height: 100%; */
  height: 40px;
  width: 300px;
  object-fit: cover;
}
.navBar-dropdown-div {
  display: flex;
  gap: 5px;
  position: relative;
}
.navBar-dropdown-div img {
  width: 10px;
}
.navBar-collapse-container {
  padding-right: 1.5rem;
  gap: 1.75rem;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
}
.navBar-nonCollapse-div {
  display: flex;
  gap: 1rem;
}
.navBar-dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 2.4rem;
  background-color: var(--white-color);
  align-items: flex-start;
  width: 12rem;
  padding: 1rem;
  line-height: 2.5rem;
  z-index: 1100;
  left: 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.navBar-dropdown-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  background: transparent;
}
.navBar-dropdown-overlay.active {
  display: flex;
}
.navBar-dropdown a {
  width: 100%;
}
.navBar-dropdown.active {
  display: flex;
}
.hambergerIcon {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e8f0;
}
.navBar-administration {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
/* --------------------------------------- */
@media screen and (max-width: 1040px) {
  .profile-dropdown {
    box-shadow: none;
  }
  .navBar-overlay.active {
    display: flex;
  }
  .navBar-dropdown {
    box-shadow: none;
  }
  .navBar-dropdown.active {
    position: relative;
    width: 20rem;
    top: 0;
    left: -2.5rem;
    padding-left: 3.1rem;
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--navBar-dropdown-color);
  }
  .navBar-dropdown-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    position: relative;
  }
  .navBar-collapse-container.active a,
  .navBar-administration {
    display: flex;
    align-items: center;
    width: 17rem;
    height: 3rem;
    position: relative;
  }
  .sidebar-underline::before {
    content: '';
    position: absolute;
    display: block;
    top: 0%;
    height: 1px;
    width: 270px;
    border: 1px solid #f4f4f498;
    left: 44%;
    z-index: 1000;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }
  .navBar-dropdown.active > a:not(:first-child):after {
    content: '';
    position: absolute;
    display: block;
    top: 0%;
    height: 1px;
    width: 270px;
    border: 0.5px solid #c3cbcd33;
    left: 39%;
    z-index: 1000;
    transform: translateX(-48%);
    -webkit-transform: translateX(-48%);
  }
  .navigation-bottom-shadow {
    width: 330px;
    border-bottom: 1px solid rgba(195, 203, 205, 0.6);
    box-shadow: 4px 2px 7px rgba(0, 0, 0, 0.35);
    transform: translateX(-15%);
    /* margin-bottom: 10px; */
  }
  .navBar-navigationText {
    font-size: 1.1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    padding-top: 0.2rem;
    width: 10rem;
    height: 2.7rem;
  }
  .hambergerIcon {
    display: flex;
  }
  .navBar-dropdown.active {
    line-height: 1.5rem;
  }
  .navBar-collapse-container {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 20rem;
    min-height: 100vh;
    position: absolute;
    z-index: 1100;
    top: 0;
    left: -100%;
    bottom: 0;
    background-color: var(--white-color);
    padding: 2.5rem;
    padding-top: 0.8rem;
    gap: 0;
  }
  .navBar-collapse-container.active {
    display: flex;
    left: 0;
    margin-left: -2rem;
  }
  .navBar-nonCollapse-div {
    margin-left: auto;
    margin-right: 3.5rem;
  }
  .navBar-administration {
    display: flex;
    justify-content: space-between;
    padding-right: 1.3rem;
  }
}

@media screen and (max-width: 580px) {
  nav {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .navBar-brand {
    width: 11rem;
  }
  .navBar-collapse-container {
    width: 100%;
    max-width: 19rem;
  }
  .bellIcon-div {
    width: 35px;
    height: 35px;
  }
  .navBar-nonCollapse-div {
    gap: 0.6rem;
  }
  .navBar-nonCollapse-div {
    margin-right: 2.8rem;
  }
  .navBar-collapse-container.active a {
    width: 16rem;
  }
  .navBar-dropdown.active {
    width: 19rem;
  }
  .sidebar-underline::before {
    width: 255px;
    left: 42%;
    transform: translateX(-46%);
    -webkit-transform: translateX(-46%);
  }
  .navBar-dropdown.active > a:not(:first-child):after {
    width: 255px;
    left: 38.5%;
    transform: translateX(-46%);
    -webkit-transform: translateX(-46%);
  }
  .navBar-administration {
    padding-right: 1.8rem;
  }
  .navBar-navigationText {
    padding-top: 0.1rem;
    width: 10rem;
    height: 2.5rem;
  }
  .navigation-bottom-shadow {
    width: 310px;
    margin-bottom: 2px;
  }
}
