.facilityProfile {
  padding-top: 23px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 1.875rem;
}
.facilityProfile-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1.5rem;
}
.facilityProfile-image-div {
  min-height: 474px;
  height: fit-content;
  background: #080ccb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.coverPic-preview-parent {
  position: relative;
}
.coverPic-preview {
  width: 400px;
  object-fit: cover;
}
.coloredLogo-preview-parent {
  position: relative;
  max-width: 200px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.coloredLogo-preview {
  width: 200px;
  object-fit: cover;
}
.previewClose-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  width: 1.5rem;
  padding: 5px;
  margin: 5px;
  border-radius: 50%;
}
.coloredClose-button {
  position: absolute;
  right: -50px;
  top: 6px;
  background-color: white;
  width: 1.1rem;
  padding: 3px;
  margin: 5px;
  border-radius: 50%;
}
.coloredClose-button-displayNone {
  display: none;
}
.profile-image {
  /* width: 400px; */
  object-fit: cover;
}
.facilityProfile-whiteLogo {
  width: 200px;
  height: 50px;
  align-self: flex-start;
  padding-left: 2.25rem;
}
.profile-whiteLogo {
  width: 300px;
  /* max-width: 100%; */
  /* height: 50px; */
  object-fit: cover;
}
.facilityProfile-coloredLogo {
  width: 200px;
  height: 50px;
  align-self: flex-start;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}
.profile-coloredLogo {
  width: 200px;
  object-fit: cover;
}
.facilityProfile-QrCode {
  width: 85%;
  height: 50px;
  border: 1px solid #3c64b1;
  border-radius: 50px;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.facilityProfile-QrCode span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
}
/* ---------------------------------- */
.facilityProfile-details {
  height: fit-content;
  min-height: 474px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.editProfile-button {
  width: 110px;
  height: 30px;
  background: #080ccb;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.editProfile-button:hover {
  background: #080ccb;
}
.details-facilityData {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-top: 2rem;
  width: 100%;
}
.details-flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex-basis: 30%;
}
.details-flex-column > span:first-child,
.details-edit-flex-column > span:first-child {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}
.details-flex-column > span:last-child {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2d3748;
}
.details-border-line {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0.5px solid #c3cbcd33;
}
.detailsLocation-heading {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #718096;
}
.category-name-list {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.category-separator {
  display: block;
  width: 2px;
  height: 1rem;
  background-color: rgba(160, 174, 192, 0.5);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

/* -------------------------------------- */
.upload-logo {
  max-width: 332px;
  width: 100%;
  height: 50px;
  left: 60px;
  top: 371px;
  border: 1px solid #3c64b1;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1rem;
}
.upload-logo > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.logo-instruction {
  display: flex;
  flex-direction: column;
}
.logo-instruction > span:first-child,
.logo-instruction > span:last-child {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a0aec0;
}
.logo-instruction > span > span {
  color: #ffffff;
}
.noImage-background,
.noColor-image-background {
  width: 400px;
  /* height: 300px; */
  max-width: 100%;
  min-height: 300px;
  background: #bdcde3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 2rem;
}
.noColor-image-background {
  background: none;
}
.noImage-background > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #080ccb;
}
.details-edit-flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex-basis: 30%;
}
.categories-edit-flex-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex-basis: 61.6%;
}
.details-heading > span {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #09101d;
}
.business-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #09101d;
}
.editable-input-label > span:first-child {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #2d3748;
}
.profile-form-input {
  border: 1px solid var(--formInput-border-color);
  width: 100%;
  border-radius: 50px;
  height: 40px;
}
.profile-form-input::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 500;
  font-size: 12px;
}
.business_profile-textarea-input {
  border: 1px solid var(--formInput-border-color);
  width: 100%;
  border-radius: 12px;
  height: 80px;
}
.business_profile-textarea-input::placeholder {
  color: var(--input-placeholder-color);
  font-weight: 500;
  font-size: 12px;
}
.details-edit-facilityData {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 2rem;
  width: 100%;
}
.profile-edit-button-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
  gap: 1rem;
}

.view-document-background-wrap {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 100;
}
.view-document-text {
  color: #080ccb !important;
}
/* --------------------------------------------- */
@media screen and (max-width: 1172px) {
  .details-edit-flex-column {
    gap: 0.75rem;
    flex-basis: 45%;
  }
  .categories-edit-flex-column {
    flex-basis: 45%;
  }
}
@media screen and (max-width: 1155px) {
  .details-flex-column {
    gap: 0.75rem;
    flex-basis: 40%;
  }
}

@media screen and (max-width: 917px) {
  .facilityProfile-container {
    flex-direction: column;
    width: 100%;
  }
  .facilityProfile-image-div {
    min-height: fit-content;
  }
  .facilityProfile-whiteLogo {
    width: 100%;
    padding-left: 0rem;
    text-align: center;
  }
  .facilityProfile-QrCode {
    width: fit-content;
  }
}

@media screen and (max-width: 580px) {
  .facilityProfile {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

@media screen and (max-width: 525px) {
  .details-edit-flex-column {
    gap: 0.75rem;
    flex-basis: 100%;
  }
  .categories-edit-flex-column {
    flex-basis: 100%;
  }
  .profile-edit-button-div {
    justify-content: center;
    /* gap: 0.5rem; */
  }
}

@media screen and (max-width: 500px) {
  .details-header {
    flex-direction: column;
    gap: 1.5rem;
  }
  .details-flex-column {
    flex-basis: 100%;
  }
  .facilityProfile-whiteLogo {
    height: 50px;
    padding-left: 0;
  }
  .facilityProfile-coloredLogo {
    width: 200px;
    height: 50px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 360px) {
  .profile-edit-button-div .form-button,
  .profile-edit-button-div .modal-cancel-button {
    min-width: 120px;
  }
}
