.activity-calender-div {
  width: 100%;
  margin-bottom: 2rem;
}
.activities-card-div {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.activity-card {
  height: 110px;
  background: #ffffff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  /* z-index: 500; */
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.activity-image {
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.activity-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 7px;
}
.activity-details > span {
  height: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}
.activity-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #09101d;
}
.activity-timing-div {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
  position: relative;
}
.activity-date-div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
}
.date-border {
  display: block;
  width: 2px;
  background-color: rgba(160, 174, 192, 0.5);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.activity-description-div {
  width: 100%;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}
.activity-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #718096;
}
.activity-action-buttons {
  width: 45px;
  height: 110px;
  right: 0;
  top: 0;
  background: #080ccb;
  border-radius: 0px 20px 20px 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.action-button-border {
  width: 70%;
  height: 2px;
  background-color: rgba(160, 174, 192, 0.5);
}

.activityForm {
  margin-bottom: 1rem;
}
.activityModal-separator {
  width: 100%;
  height: 2px;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  margin-bottom: 1rem;
}
.activity-date-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
.activityCard-navigator-div {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activityCard-guest-div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
}
.activityCard-navigator-div .view-details,
.activityCard-navigator-div .guest-numbers {
  font-family: 'Inter';
  font-style: normal;
  font-size: 12px;
  color: #080ccb;
}
.activityCard-navigator-div .view-details {
  font-weight: 400;
}
.activityCard-navigator-div .guest-numbers {
  font-weight: 600;
}

.repeatType-dropdown {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.repeatDays-checkbox {
  padding-bottom: 1rem;
}
/* multi date select---------------------------- */
.rmdp-container {
  width: 100%;
  margin-bottom: 1rem;
}
/* .rmdp-input, */
.datePicker-input {
  margin: 1px 0;
  padding: 2px 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 45px;
  border-radius: 100px !important;
  border: 1px solid var(--formInput-border-color);
  height: 100%;
  padding-right: 35px;
  padding-left: 10px;
}
/* .rmdp-input:focus, */
.datePicker-input:focus {
  border: 1px solid #86b7fe !important;
}
.multiselect-calender-action-btn-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem;
}
.multiselect-calender-action-btn-div .button {
  background-color: #080ccb;
  color: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 8px;
  border: unset;
  border-radius: 5px;
}
.icon-tabler {
  margin-right: 10px;
}
/* ------------------------------------------ */

@media screen and (max-width: 660px) {
  .activities-card-div {
    justify-content: center;
  }
}
@media screen and (max-width: 440px) {
  .activity-card {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .activity-image {
    margin-right: 10px;
  }
  .activity-timing-div {
    flex-direction: column;
  }
  .date-border {
    display: none;
  }
  .activity-card {
    height: 130px;
  }
  .activity-action-buttons {
    height: 130px;
  }
  .activity-description-div {
    height: fit-content;
    max-height: 60px;
  }
}
@media screen and (max-width: 550px) {
  .center-activity-label {
    display: flex;
    /* justify-content: center; */
  }
}
/* -----------------responsive code------------------------ */
.activities-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  gap: 1.5rem;
  margin-top: 1rem !important;
}
.activity-card-details-R {
  margin-right: 45px;
  width: 100%;
}
.activity-card-image-R {
  min-width: 60px;
}
@media screen and (max-width: 1250px) {
  .activities-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 830px) {
  .activities-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
.w-h-15 {
  width: 15px;
  height: 15px;
}
.pagination-down-arrow-container-activity {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
