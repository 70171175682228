.spinner {
  margin: 100px auto;
  width: 100px;
  height: 75px;
  text-align: center;
  font-size: 20px;
}

.rect1 {
  background-color: #080ccb;
}
.rect2 {
  background-color: #3438d0;
}
.rect3 {
  background-color: #6163d6;
}
.rect4 {
  background-color: #8c8eda;
}
.rect5 {
  background-color: #b9b9e0;
}

.spinner > div {
  /* background-color: #333; */
  height: 100%;
  width: 8px;
  display: inline-block;
  margin: 0 0.2rem;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  -moz-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  -moz-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  -moz-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  -moz-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-moz-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -moz-transform: scaleY(0.4);
  }
  20% {
    -moz-transform: scaleY(1);
  }
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
    -moz-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
  }
}
