.registration-form-bg {
  width: 900px;
  background-color: var(--white-color);
  padding: 4rem 3rem 4rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  /* overflow-y: scroll; */
}
.registration-form {
  display: flex;
  flex-direction: column;
  /* padding-right: 2rem; */
}
.registration-form-input {
  border: 1px solid var(--formInput-border-color);
  width: 100%;
  border-radius: 50px;
  height: 45px;
}
.registration-form-input::placeholder {
  color: var(--input-placeholder-color);
  /* font-size: var(--input-placeholder-font); */
  font-weight: 500;
  font-size: 12px;
}
.location-address {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: var(--location-address-color);
  padding-bottom: 1rem;
}
.registration-button-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registration-form-link-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--form-guide-text-font);
  color: var(--link-text-color);
  padding-right: 1rem;
}
.successRegistration-page .success-message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: green;
  padding-top: 1rem;
  text-align: left;
  text-align: center;
}
.successRegistration-page .adminApproval-message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #718096;
  padding-top: 0.5rem;
  text-align: left;
}
.login-padding {
  /* padding-right: 0.25rem!important; */
  padding-left: -0.75rem !important;
}
.multi-files-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 1300px) {
  .registration-form-bg {
    width: 800px;
  }
}
@media screen and (max-width: 980px) {
  .registration-form-bg {
    width: 700px;
    padding: 3rem 3rem 3rem;
    padding-right: 1.75rem;
  }
}
@media screen and (max-width: 820px) {
  .registration-form-bg {
    width: 700px;
    padding: 3rem 3rem 3rem;
    padding-right: 1.75rem;
  }
}
@media screen and (max-width: 768px) {
  .registration-form-bg {
    width: 500px;
    padding: 2rem 2rem 2rem;
    padding-right: 1.25rem;
  }
  .successRegistration-page .adminApproval-message {
    text-align: left;
  }
  .disabled-feature-parent {
    flex-direction: column;
    gap: 1rem;
  }
}
@media screen and (max-width: 620px) {
  .registration-form-bg {
    width: 100%;
    align-items: center;
    height: 700px;
  }
  .registration-form {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .registration-form::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background-color: #080ccb;
  }
  .registration-form-input {
    /* text-align: center; */
  }
  .registration-form-input::placeholder {
    /* text-align: center; */
  }
  .registration-form-input:placeholder-shown {
    /* text-align: center; */
  }
  .location-address {
    /* text-align: center; */
  }
  .registration-button-div {
    flex-direction: column;
    /* align-items: center; */
    gap: 1rem;
    margin-top: 1rem;
  }
  .registration-form-link-text {
    padding-right: 0.75rem;
    font-size: 12px;
  }
  .disabled-features-normal-checkbox {
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .normal-checkbox-label {
    flex-basis: 45%;
  }
  .toggle-checkbox-label {
    display: flex;
    justify-content: center;
    /* align-items: center !important; */
    gap: 0.5rem;
  }
  .toggle-checkbox-label .form-check-input {
    padding-top: 0.3rem;
  }
}
@media screen and (max-width: 550px) {
  .successRegistration-page .adminApproval-message {
    text-align: center;
  }
}
