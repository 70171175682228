.dining-page {
  margin-bottom: 6.5rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.diningPage-info {
  display: flex;
  gap: 7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* -----------------------DINING PAGE CSS----------------- */
.page-boundaries {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}
.dining-page .amenitiesPage-head {
  padding-top: 2rem;
  align-items: flex-start;
}
.diningPage-heading-div {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.restImage-div,
.restImage-noImage-div {
  min-width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.restImage-diningPage {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}
.restImage-noImage-div {
  background: #bdcde3;
}
.diningPage-heading {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.dining-page .addAmenities-button {
  min-width: 125px;
}
.diningContent-div {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  margin-top: 2rem;
}
.diningContent-div .calender-content-div {
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  gap: 1.5rem;
}
.calender {
  background: #ffffff;
  height: 160px;
  width: 100%;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.month-today {
  position: absolute;
  top: 21px;
  right: 16px;
  border: 1px solid #080ccb;
  border-radius: 4px;
  padding: 2px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.month-today span {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #080ccb;
}
.calender-month-year {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.calender-month-year {
  display: flex;
  justify-content: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #09101d;
}
.card-wrap-heading,
.repeatList-div {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #09101d;
}
.dining-card {
  margin-top: 1.5rem;
}
.no-dining-meals-div {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.no-dining-meals-text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #a0aec0;
}
.mealdata-modal-header {
  display: flex;
}
.mealdata-modal-heading {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.75rem;
  flex-basis: 50%;
}
.mealdata-modal-heading-category {
  padding: 10px 0 10px 1rem;

  flex-basis: 50%;
  flex-direction: column;
}
.category-name {
  padding-top: 0.75rem;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}
.mealItem-parent-div {
  display: flex;
  justify-content: space-between;
}
.mealdate-regular {
  flex-basis: 50%;
  padding: 0.5rem 1rem 3rem;
  width: 100%;
}
.regular-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d3748;
}
.mealItem-regular-border,
.mealItem-exclusive-border {
  width: 100%;
  height: 1px;
  padding-bottom: 1rem;
}
.mealItem-regular-border {
  border-top: 1px solid rgba(113, 128, 150, 0.15);
}
.mealItem-exclusive-border {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.mealdate-exclusive {
  flex-basis: 50%;
  background-color: #080ccb;
  padding: 0.5rem 1rem 1rem;
  border-bottom-right-radius: 19px;
}
.exclusive-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.items-parent-wrap {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 1rem;
}
.item-progress-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-top: 3px;
}
.progress-dot {
  width: 10px;
  height: 11px;
  border-radius: 17px;
}
.mealdate-regular .progress-dot {
  background-color: #080ccb;
}
.mealdate-exclusive .progress-dot {
  background-color: #ffffff;
}
.item-progressbar {
  width: 1px;
  height: 90%;
  border-left: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-regular .item-progressbar {
  border-left: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-exclusive .item-progressbar {
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
}
.hide-progressbar {
  display: none;
}
.item-details-parent {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 1rem;
}
.mealdate-regular .item-details-parent {
  border-bottom: 1px dashed rgba(113, 128, 150, 0.15);
}
.mealdate-regular .item-details-parent:last-child {
  border-bottom: none;
}
.mealdate-exclusive .item-details-parent {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
}
.mealdate-exclusive .item-details-parent:last-child {
  border-bottom: none;
}
.item-detail-parent:last-child {
  border-bottom: transparent;
}
.item-name-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  flex-basis: 80%;
  width: 100%;
}
.item-price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-basis: 20%;
}
.title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
.mealdate-regular .title {
  color: #09101d;
}
.mealdate-exclusive .title {
  color: #ffffff;
}
.description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.mealdate-regular .description {
  color: #718096;
}
.description {
  color: #ffffff;
}
.price {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}
.mealdate-regular .price {
  color: #080ccb;
}
.mealdate-exclusive .price {
  color: #ffffff;
}
.modalHeadingRight {
  width: 50%;
  flex-basis: 50%;
  background-color: #080ccb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  border-top-right-radius: 19px;
}
.repeatList-div {
  width: 300px;
  min-width: 300px;
  min-height: 60vh;
  height: fit-content;
  background: #ffffff;
  border-radius: 16px;
  padding: 25px 16px 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.repeatList-heading {
  height: 30px;
  width: 100%;
}
.repeatList-underline {
  margin-top: 1rem;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
}
.calenderSwiper-container {
  position: relative;
  display: flex;
  align-items: center;
}
.month-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #718096;
  padding: 2px 8px;
  height: 22px;
  margin: 0 10px;
  cursor: pointer;
}
.calender-arrow-container {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #718096;
  padding: 5px 12px;
  max-height: 37px;
}
.activity-calender-div .calender-arrow-container {
  width: 62px !important;
  max-width: 35px !important;
  min-width: 35px !important;
}
.week-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
  height: 80px;
}
.week-date-container.active {
  background-color: #080ccb;
  border-radius: 100px;
  padding: 5px 0px;
  width: 46px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activeClass-week,
.activeClass-date {
  font-family: 'Inter';
  font-style: normal;
  color: #718096;
}
.activeClass-week {
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
}
.activeClass-date {
  font-weight: 600;
  font-size: 14px;
  color: #09101d;
  position: relative;
  background-color: #ffffff;
  padding: 1px;
  border-radius: 100px;
  width: 26px;
}
.week-date-container .week,
.week-date-container .date {
  font-family: 'Inter';
  font-style: normal;
  color: #718096;
}
.week-date-container .week {
  font-weight: 400;
  font-size: 11px;
}
.week-date-container .date {
  font-weight: 600;
  font-size: 14px;
}

.diningPage-menu-div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
}
.diningPage-menu-div .restaurant-time-div {
  display: flex;
  align-items: center;
  justify-content: unset !important;
  gap: 0.5rem;
  width: unset !important;
}
.viewMenu-btn-separator {
  height: 20px;
  width: 1px;
  border-right: 2px solid rgba(113, 128, 150, 0.15);
}
.viewMenu-btn {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #080ccb;
}
.dining-fullScreen-menu-slider {
  width: 100vw;
  /* height: 100vh; */
  overflow: hidden;
  object-fit: contain;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}
.dining-fullScreen-menu-slider .swiper-wrapper {
  height: 100vh;
}
.dining-fullScreen-menu-slider .swiper-pagination-bullet-active {
  background-color: #ffffff;
}
.dining-fullScreen-menu-slider .swiper-slide {
  padding: 3rem;
}
.dining-fullScreen-menu-slider .swiper-button-next,
.dining-fullScreen-menu-slider .swiper-button-prev {
  color: #ffffff;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
/* ---------swiper class------------ */
.activity-calender-div .swiper,
.calender-content-div .swiper {
  height: 100%;
}
.activity-calender-div .swiper-wrapper,
.calender-content-div .swiper-wrapper {
  display: flex;
}
.calender-content-div .swiper {
  width: calc(100vw - 12rem - 300px - 1.5rem);
}
.calender-content-div .swiper-wrapper {
  width: calc(100vw - 12rem - 300px - 1.5rem) !important;
}
.activity-calender-div .swiper-slide,
.calender-content-div .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  margin-right: 10px !important;
  display: flex;
  justify-content: center;
}

.activity-calender-div .swiper-slide img,
.calender-content-div .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activity-calender-div .swiper-button-next:after,
.calender-content-div .swiper-button-next:after {
  margin-left: 10px !important;
}
.activity-calender-div .swiper-button-next,
.calender-content-div .swiper-button-next {
  right: 3px !important;
}
.activity-calender-div .swiper-button-prev:after,
.calender-content-div .swiper-button-prev {
  left: 3px !important;
}
/* -------------------accordion class----------- */
.accordion-item {
  border: 0;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
}
.accordion-header {
  border-bottom: 0;
}
.accordion-button::after {
  background-image: url('/public/assets/accordionOpen.svg');
}
.accordion-button:not(.collapsed)::after {
  background-image: url('/public/assets/accordionClose.svg');
}
.accordion-button:not(.collapsed) {
  color: #09101d;
  background-color: #ffffff;
  box-shadow: none;
}
.accordion-button:not(.collapsed)::after.accordion-item {
  border-bottom: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none;
}
.accordion-button {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #09101d;
  padding: 1rem 0;
}
.accordion-body {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.accordion-button:focus {
  border-color: #ffffff;
}
.accordion-noData {
  width: 100%;
  height: 490px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #a0aec0;
}
/* ------------meal card--------------- */
.perDay-mealCard,
.repeat-mealCard {
  width: 100%;
  height: 75px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px;
}
.repeat-mealCard {
  border: 1px solid rgba(113, 128, 150, 0.15);
}
.perDay-mealCard {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.mealImage {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 8px;
}
.mealCard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.3rem;
  width: 100%;
}
.mealCard-content .mealName {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #09101d;
  width: 100%;
  height: 17px;
  overflow-y: auto;
  padding-right: 5px;
}
.mealCard-content .mealName::-webkit-scrollbar {
  display: none;
}
.mealCard-content .mealName {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mealCard-content .mealItemNumb {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #718096;
}
.mealItemNumb {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.mealCard-content .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2px;
  position: relative;
}
.dingingDropdown-overlay {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 450;
}
.diningDropdown-wrap {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 500;
  min-width: 115px;
}
ul {
  padding: 0 !important;
}
.diningDropdown {
  background-color: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 8px 8px;
}
.diningDropdown li {
  padding: 10px;
  border-top: 1px solid rgba(113, 128, 150, 0.15);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #09101d;
}

/* ----------responsive media queries---------------- */
.dining-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1.5rem;
}
.accordion-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  gap: 1.5rem;
}
.dining-buttons-class {
  display: flex;
  gap: 1.5rem;
}
@media screen and (max-width: 670px) {
  .dining-page .amenitiesPage-head {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
  .dining-buttons-class {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.category-parent-div {
  width: 100%;
  padding: 15px;
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
}
@media screen and (max-width: 1660px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 1320px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 960px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}

/* --------------media queries----------------- */

@media screen and (max-width: 770px) {
  .diningContent-div {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .calender-content-div .swiper {
    width: 100%;
  }
  .repeatList-div {
    width: 100% !important;
  }
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
  .accordion-body {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .repeat-mealCard {
    width: calc((100vw - 3.75rem - 50px) / 2);
  }
}

.modalHeadingRight.single {
  background-color: transparent;
  display: none;
}
.mealItem-regular-border.single {
  display: none;
}
.mealItem-exclusive-border.single {
  display: none;
}
.mealdata-modal-heading.single {
  flex-basis: 100%;
  padding-right: 1rem;
}
.mealdata-modal-heading-category-single {
  padding: 10px 0 10px 1rem;

  flex-basis: 100%;
  flex-direction: column;
}
.mealdate-regular.single {
  flex-basis: 100%;
}
.mealdate-exclusive.single {
  flex-basis: 100%;
  border-bottom-right-radius: 19px;
  border-bottom-left-radius: 19px;
  padding-top: 1rem;
}
.mealCard-content-heading.single {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 740px) {
  .modalHeadingRight {
    background-color: transparent;
    display: none;
  }
  .mealItem-regular-border {
    display: none;
  }
  .mealItem-exclusive-border {
    display: none;
  }
  .mealItem-parent-div {
    flex-direction: column-reverse;
  }
  .mealdate-exclusive {
    border-bottom-right-radius: 0;
    padding-top: 1rem;
  }
  .mealdate-regular {
    padding-top: 1rem;
  }
  .mealdata-modal-heading {
    flex-basis: 100%;
    padding-right: 1rem;
  }
  .mealdata-modal-heading-category {
    padding: 10px 0 10px 1rem;

    flex-basis: 100%;
    flex-direction: column;
  }
  .mealCard-content-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media screen and (max-width: 630px) {
  .accordion-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .repeat-mealCard {
    width: 100%;
  }
}
@media screen and (max-width: 580px) {
  .page-boundaries {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
@media screen and (max-width: 550px) {
  .dining-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  .accordion-body {
    flex-direction: column;
  }
  .repeat-mealCard {
    width: 100%;
  }
}
@media screen and (max-width: 522px) {
  .viewMenu-btn-separator {
    display: none;
  }
  .diningPage-menu-div {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 460px) {
  .calender-month-year {
    justify-content: flex-start;
  }
  .dining-page .amenitiesPage-head {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
  .dining-page .addAmenities-button {
    width: 100%;
  }
}
.category-list {
  width: 70%;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.category-list-parent {
  display: flex;

  margin-bottom: 30px;
}
.category-list-action {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.action-img {
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
}
.category-form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
}
.category-list-action-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
}
.category-list-action-popover {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}
.p-10px {
  padding: 10px;
}
.delete-txt {
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.meal-category {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #718096;
}
.relative {
  position: relative;
}
.padding-b-100 {
  padding-bottom: 100px;
}
