.ratingsreviews-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.rating-star-icon{
    padding-right: 0.25rem;
    padding-bottom: 0.1rem;
    text-align: center;
}
.avg-reviews-ratings{
    display: flex;
    gap: 1rem;
}
.avg-ratings{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}
.avg-reviews-ratings > div, .blue-rating-font{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #080CCB;
}
.avg-reviews-ratings > div > span{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #7A7B9F;
}
.blue-rating-font{
    color: #080CCB !important;
    padding-top: 1px;
}
.review-card-parent{
    background: white;
    border-radius: 10px;
    width: 100%;
    height: fit-content;
    padding: 30px;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);

}
.review-card{
    width: 571px;
    height: 150px;
    background: #E2E8F0;
    border-radius: 20px;
    padding: 0.75rem;
}
.review-card-flex-column{
    display: flex;
    flex-direction: column;
}
.review-card-flex-row-spaceBetween{
    display: flex;
    justify-content: space-between;
}
.review-card-flex-row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
}
.review-user-image{
    width: 40px;
    height: 40px;
    background: #A0AEC0;
    box-shadow: 0px 0px 20px rgba(8, 12, 203, 0.15);
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-user-imageClass {
    width: 40px;
    height: 40px;
    border-radius: 60px;
    object-fit: cover;
}
.review-user-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #09101D;
}
.review-date{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #718096;
}
.review-description{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #718096;
    height: 5rem;
    overflow-y: auto;
}
.facility-review-description{
    height: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0px;
    padding-left: 3.25rem;
}
.userName-overflow{
    height: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
}
.userName-overflow-div{
    margin-right: 5px;
    flex-wrap: wrap;
    width: fit-content;
}
.userImage-overflow{
    min-width: 40px;
}
.fit-content{
    width: max-content;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 430px) {
    .ratingsreviews-head{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .review-card-flex-row-spaceBetween{
        flex-direction: column;
        justify-content: none;
        align-items: flex-start;
    }
    .review-description{
        height: 2.5rem;
    }
    .facility-review-description{
        padding-left: 3.3rem;
    }
    .smallScreen-rating{
        padding-left: 3.25rem;
    }
   
}
/* ----------------------review image gallery css--------------------- */
.review-image-wrap-div{
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 428px;
    margin: 10px 0;
}
.review-image-divClass{
    width: 10rem;
    height: 10rem;
    background-color: #718096;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.review-image-imageClass{
    width: 10rem;
    height: 9rem;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 1rem;
    
    

}
.review-image-imageClass-1{
    width: 10rem;
    height: 9rem;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 1rem;

    border: 3px solid #080CCB;

}
.gallery-image-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    position: relative;
    justify-content: center;
    /* border:2px solid black; */
    width: 10rem;
    height: 12rem;

}
.gallery-image-wrap-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    padding-top:10px;
    position: relative;
    /* border:2px solid black; */
    width: 10rem;
    height: 12rem;

}
.gallery-image-wrap-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* padding-top: 10px; */
    /* padding-bottom: 10px;
    /* padding-top:10px;  */
    position: fixed;
    /* border:2px solid black; */
    width: 10rem;
    height: 14rem;

}
.review-image-delete-icon{
    position: absolute;
    border-radius: 50%;
    background: #BDCDE3;
    width: fit-content;
    right: 0;
    top: 0;
    padding: 9px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.review-image-delete-icon-2{
    position: absolute;
    border-radius: 50%;
    background: #F22110;
    width: fit-content;
    right: -12px;
    top: 17px;
    padding: 9px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.rating-arrow-mark{
    background-color: #09101D;
    width: 100%;
    height: 100%;
}
/* ---------------------responsive code ------------------------ */
.facility-reviews-parent-card{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    gap: 1rem;
}
.facility-reviews-card{
    background: #E2E8F0;
    border-radius: 20px;
    padding: 15px;
    height: fit-content;
    min-height: 142px;
}
.business-reviews-card{
    background: #E2E8F0;
    border-radius: 20px;
    padding: 15px;
    height: fit-content;
}
.review-image-style{
    padding-right: 30px;

    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 7px;
}
.review-default-div{
    width: 400px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.review-image-card-default{
    width: 400px;
    height: 300px;
    object-fit: cover;
}
.slider-width{
    width: 86%;
    justify-content: center;
    align-items: center;
    /* align-items: flow-root; */
    margin-right: -33px;
    margin-left: 24px;

}
.slider-width-1{
    width: 86%;
    justify-content: center;
    align-items: center;
    /* align-items: flow-root; */
    margin-right: -33px;
    margin-left: 24px;

}
.prev-button{
    position: absolute;
    z-index: 1000;
    top: 57px;
    /* right: 94px; */
    left: -21px;
}
.next-button{
    top: 57px;
    position: absolute;
    z-index: 1000;
    right: -33px;
}
.delete-icon-style{
    width: 85%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-image{
    width: 85%;
    height: 80%;
    padding-left: 80px;
    padding-top: 90px;
}
.gallery-name{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
@media screen and (max-width: 1300px) {
    .facility-reviews-parent-card{
        grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    }
}
@media screen and (max-width: 750px) {
    .facility-reviews-parent-card{
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
}