.bookings-page{
    padding-bottom: 1rem;
}
.bookings-sub-navbar-div{
    display: flex;
    justify-content: flex-start;
}
.bookings-sub-navbar {
    width: 140px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid transparent;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #718096;
}
.bookings-sub-navbar.active{
    color: #080CCB;
    border-bottom: 2px solid #080CCB;
}
.bookings-slider-parent{
    display: flex;
}
.bookings-slider-div{
    width: 280px;
    height: 85px;
    background: #080CCB;
    border-radius: 10px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    background-repeat: no-repeat;
    background-position: right;
}
.bookings-slider-div span:first-child{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    padding-left: 1rem;
}
.bookings-slider-div span:last-child{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.7);
}
.bookings-slider-div .coming-soon{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.7);
}
.first-slide-background{
    background-image: url('../../../public/assets/circleIcon1.svg');
    background-size: contain;
}
.second-slide-background{
    background-image: url('../../../public/assets/circleIcon2.svg');
    background-size: contain;
}
.third-slide-background{
    background-image: url('../../../public/assets/circleIcon3.svg');
    background-size: contain;
}
.fourth-slide-background{
    background-image: url('../../../public/assets/circleIcon4.svg');
    background-size: contain;
}
.slick-slide{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .slick-track{
    display: flex;
  }

.table-action-buttons{
    display: flex;
    gap: 0.5rem;
}
.action-approve-button{
    width: 74.76px;
    height: 22.43px;
    left: calc(50% - 74.76px/2 + 406.38px);
    top: calc(50% - 22.43px/2 - 27.79px);
    background: linear-gradient(90deg, #080CCB 0%, #9808CB 100%);
    border-radius: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}
.action-cancel-button{
    width: 74.76px;
    height: 22.43px;
    left: calc(50% - 74.76px/2 + 488.62px);
    top: calc(50% - 22.43px/2 - 68.21px);
    background: #A0AEC0;
    border-radius: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
  