.amenities-page {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 6.5rem;
}
.amenitiesPage-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.addAmenities-button {
  width: 150px;
  height: 40px;
  background: linear-gradient(90deg, #080ccb 0%, #9808cb 100%);
  border-radius: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}
.amenitiesPage-head h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #09101d;
}
/* ------------------card-------------------- */
.amenities-card-div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.amenities-card {
  position: relative;
  height: 230px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.amenities-image-container {
  height: 140px;
  /* background: #bdcde3; */
  background: #080ccb;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
}
.amenities-image-container .noImageDivClass {
  margin: auto;
}
.amenities-image-div {
  /* background: #a0aec0; */
  background-color: #bdcde3;
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.amenities-image {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.amenities-action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 45px;
}
.amenity-editIcon,
.amenity-deleteIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-basis: 50%;
}
.amenities-body {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 0.75rem;
}
.amenities-body-name span:first-child {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #09101d;
}
.amenities-body-name {
  display: flex;
  flex-wrap: wrap;
  /* height: 20px; */
  overflow-x: hidden;
  overflow-y: hidden;
}
.amenities-body-overflow > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #718096;
}
.amenities-body-overflow {
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  overflow-x: hidden;
  overflow-y: auto;
}
.document-file-name {
  overflow-x: hidden;
  overflow-y: auto;
  height: 70px;
}

.document-file-name::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #bebebe;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.document-file-name::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 6px;
}
.document-file-name::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

@media screen and (max-width: 580px) {
  .amenities-page {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  .amenities-card-div {
    justify-content: center;
  }
}
/* -----------------------response code------------------------ */
.amenities-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1.5rem;
}
@media screen and (max-width: 1300px) {
  .amenities-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 900px) {
  .amenities-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 580px) {
  .amenities-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
