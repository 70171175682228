/* -------------------Dining Form CSS---------------- */
.add-items {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
}
.diningItems-wrap {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.diningItems-scroll-container {
  width: 100%;
  height: 370px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.diningItems-scroll-container::-webkit-scrollbar {
  display: none;
}
.deleteIcon-wrap {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  height: 112%;
}
.deleteIcon-wrap img {
  cursor: pointer;
}
.delete-progress-bar {
  height: 100%;
  width: 5px;
  border: 0;
  border-left: 1px dashed rgba(113, 128, 150, 0.2);
  height: 120%;
  display: flex;
}
.hide-progress-bar {
  display: none;
}
.item-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.dining-input-title {
  height: 41px;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  padding: 13px 16px;
}
.dining-input-price {
  padding: 13px 16px;
  height: 41px;
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
}
.dining-input-price.mobile {
  display: none;
}
.dining-input-description {
  height: 60px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 15px 16px;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  /* line-height: 15px; */
  color: #718096;
}
.dining-input-description::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: var(--input-placeholder-color);
}
.item-type-buttons {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}
.item-type-buttonGroup {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.normalItem-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  gap: 8px;
  width: 100%;
  /* width: 48.75%; */
  height: 41px;
  background: #e2e8f0;
  border-radius: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #a0aec0;
  cursor: pointer;
}
.exclusiveItem-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  gap: 8px;
  width: 100%;
  /* width: 48.75%; */
  height: 41px;
  background: #080ccb;
  border-radius: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
}
.addMore {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #080ccb;
}
.weekday-checkbox-div {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap;
}
.weekday-checkbox {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-basis: 25%;
  gap: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #a0aec0;
}
.dining-date-input {
  height: 42px;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
}
.addMealType-date-element {
  display: flex;
  width: 100%;
}
.noDiningItems-span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #a0aec0;
  justify-self: center;
  text-align: center;
}
.pdf-viewer-wrap {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  /* border: 2px solid rgba(113, 128, 150, 0.15); */
  display: flex;
  justify-content: center;
}
.margin-top-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.menu-image-title {
  position: absolute;
  bottom: -20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #718096;
  width: 80px;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
}

@media screen and (max-width: 855px) {
  .weekday-checkbox {
    flex-basis: 33%;
  }
  .weekday-checkbox-div {
    justify-content: space-between;
  }
}
@media screen and (max-width: 745px) {
  .dining-input-price.mobile {
    display: flex;
  }
  .dining-input-price {
    display: none;
  }
}
@media screen and (max-width: 610px) {
  .dining-modal-div {
    flex-direction: column-reverse;
  }
  .modal-right-div {
    margin-bottom: 0px !important;
  }
  .modal-left-div {
    margin-bottom: 4rem;
  }
  .weekday-checkbox {
    flex-basis: 25%;
  }
  .diningItems-scroll-container {
    height: 260px;
  }
  .modal-right-date-div-wrap {
    width: 100%;
  }
}
@media screen and (max-width: 430px) {
  .modal-right-wrap .date-time-parent-div {
    flex-direction: row;
    gap: 0.5rem;
  }
  .weekday-checkbox {
    flex-basis: 33%;
  }
  .diningItems-wrap {
    gap: 0.5rem;
  }
}
@media screen and (max-width: 348px) {
  .exclusiveItem-button,
  .normalItem-button {
    font-size: 10px;
  }
}
