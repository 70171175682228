* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
  font-family: 'Raleway', sans-serif;
}
:root {
  --extraSmall-font: 0.75rem;
  --small-font: 1rem;
  --medium-font: 1.5rem;
  --large-font: 2rem;
  --white-color: #ffffff;
  --activeLink-color: #ff6600;
  --footer-background: #6c757d;
  --input-placeholder-color: #a0aec0;
  --link-text-color: #718096;
  --formInput-border-color: #e2e8f0;
  --form-guide-text-font: 0.85rem;
  --theme-dark-blue: #080ccb;
  --form-button-font: 0.8rem;
  --input-placeholder-font: 0.9rem;
  --forgotPassword-helperText-font: 0.8rem;
  --form-title-font: 1.25rem;
  --forgotPassword-helperText: #718096;
  --location-address-color: #718096;
  --navBar-dropdown-color: #e2e8f0;
  --navBar-sidebar-underline: #f4f4f4;
  --navBar-sidebar-dropdown-underline: #c3cbcd;
  --navLink-color: #718096;
  --navLink-active-color: #080ccb;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
li {
  list-style: none;
}

.header-div-fixed {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000;
  /* box-shadow: 0px 0px 8px 2px #000000; */
  box-shadow: 0px 2px 8px -1px rgba(0, 0, 0, 0.3);
}

.password-hideIcon-wrap {
  position: relative;
}
.password-eye {
  position: absolute;
  top: 16px;
  right: 13px;
}

.noImageClass {
  max-width: 100%;
  max-height: 100%;
}
.form-edit-disabled {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}

.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed !important;
}

.button-spaceBetween {
  display: flex;
  justify-content: space-between;
}
.mr-10 {
  margin-right: 10px;
}
.error {
  color: red;
  font-size: 0.87rem;
  font-weight: 400;
  margin-top: 3rem;
}
.no-review-image-class {
  color: red;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.no-reviews-class {
  color: red;
  font-size: 12px;
}
.deleted-userName-class {
  color: #718096;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.font-medium {
  font-size: var(--medium-font);
}
.font-small {
  font-size: var(--small-font);
}
.footer {
  background-color: var(--footer-background);
  color: var(--white-color);
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.padding-bottom-2rem {
  padding-bottom: 2rem;
}
.padding-bottom-1rem {
  padding-bottom: 1rem;
}
.padding-bottom-min {
  padding-bottom: 0.5rem;
}
.padding-top-min {
  padding-top: 0.5rem;
}
.padding-top-left-min {
  padding-top: 0.5rem;
  padding-left: 3.3rem;
}
.padding-top-1_5rem {
  padding-top: 1.5rem;
}
.padding-top-1rem {
  padding-top: 1rem;
}
.page-heading-padding {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.white-color {
  color: var(--white-color) !important;
}
.width-100 {
  width: 100%;
}
.page-background {
  /* min-height: 100vh; */
  min-height: 81vh;
  background: linear-gradient(180deg, #dcddff 0%, rgba(210, 211, 255, 0) 100%);
}
.btn-secondary {
  border-color: transparent !important;
}
.textarea-input {
  height: 7rem;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.textarea-input::placeholder {
  font-weight: 500;
  font-size: 12px;
  color: var(--input-placeholder-color);
}
input[type='text'],
input[type='date'],
input[type='time'],
input[type='select'],
input[type='number'],
input[type='email'],
input[type='textarea'],
option {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.selectbox-input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--input-placeholder-color);
}
.selectbox-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}

.date-input {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  max-width: 175px;
  height: 45px;
  cursor: pointer;
}
.date-input[type='time']:focus {
  max-width: 175px;
}
.date-input[type='date']:focus {
  max-width: 175px;
}
.date-input {
  width: 100%;
}

.date-input.placeholderClass::before {
  width: 100%;
  content: attr(placeholder);
  font-weight: 500;
  font-size: 12px;
  color: var(--input-placeholder-color);
}

.date-input.placeholderClass:focus::before {
  width: 0%;
  content: '';
}
.date-input.hasValue {
  content: attr(value);
}

.date-time-parent-div {
  display: flex;
  gap: 0.8rem;
}
.date-time-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  /* flex-basis: 50%; */
}

.image-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #2d3748;
}
.form-label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.uploadImage-text,
.uploadImage-input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}
.uploadImage-input {
  color: #718096;
  font-weight: 600;
  max-width: 19rem;
  overflow-y: auto;
  height: 15px;
  overflow-x: hidden;
}

/* -------------geo encoding input css--------------- */
.geoLocation-input-div {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.addNewFacility.geoLocation-input-div {
  margin-right: 0;
  margin-bottom: 1rem;
}
.css-yk16xz-control,
.css-2b097c-container,
.css-1pahdxg-control {
  border: 1px solid #e2e8f0 !important;
  /* width: 20rem; */
  width: 100%;
  border-radius: 50px !important;
  height: 2.75rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
  margin-right: 1rem;
}
.css-yk16xz-control::placeholder {
  width: 20rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}
.css-1wa3eu0-placeholder {
  color: #a0aec0 !important;
}
.css-2b097c-container {
  border: none !important;
}
/* ------------react select input css ----------- */
.category-select-box .css-1s2u09g-control {
  border: 1px solid var(--formInput-border-color) !important;
  border-radius: 25px !important;
  min-height: 45px !important ;
}
.businessProfile-select-box .css-1s2u09g-control {
  min-height: 40px !important ;
  border-radius: 25px !important;
  border: 1px solid var(--formInput-border-color) !important;
}
.css-6j8wv5-Input,
.css-26l3qy-menu {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 15px;
  color: #718096 !important;
}
.css-14el2xx-placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  color: #a0aec0 !important;
}
.category-select-box .css-1pahdxg-control {
  height: fit-content !important;
  border-radius: 25px !important;
  min-height: 45px !important ;
}
.businessProfile-select-box .css-1pahdxg-control {
  height: fit-content !important;
  border-radius: 25px !important;
  min-height: 40px !important ;
}
/* ------------custom error class----------- */
.category-error-class .css-1s2u09g-control {
  border: 1px solid red !important;
  border-radius: 25px !important;
  min-height: 45px !important ;
}
.category-error-class .css-1pahdxg-control {
  border: 1px solid transparent;
}
.businessProfile-error-class .css-1s2u09g-control {
  border: 1px solid red !important;
  border-radius: 25px !important;
  min-height: 40px !important ;
}
.businessProfile-error-class .css-1pahdxg-control {
  border: 1px solid transparent;
}
/* ------------supporting business---------------- */
.business-category-details {
  height: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px;
}
/* -------------Auth form css-------------- */
.logo {
  width: 12rem;
}
.blue-bg {
  background-color: var(--theme-dark-blue);
  width: 100%;
  min-height: 100vh;
  flex-basis: 12%;
}
.login-bg {
  flex-basis: 88%;
  width: 100%;
  background-image: url(/public/assets/formBg.svg),
    linear-gradient(90deg, #dcddff 0%, rgba(210, 211, 255, 0) 100%);
  background-repeat: no-repeat;
  background-size: cover;
}
.form-title {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: var(--form-title-font);
}
.form-div {
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1000;
  margin-left: -6vw;
}
.form-guide-link {
  color: var(--theme-dark-blue);
  font-size: var(--form-guide-text-font);
  font-weight: 500;
}
.form-input {
  border: 1px solid var(--formInput-border-color);
  width: 100%;
  border-radius: 50px;
  height: 2.75rem;
  cursor: pointer;
}
.form-input::placeholder {
  color: var(--input-placeholder-color);
  /* font-size: var(--input-placeholder-font); */
  font-weight: 500;
  font-size: 12px;
}
.form-button {
  background: linear-gradient(90deg, #080ccb 0%, #9808cb 100%) !important;
  border-radius: 50px;
  width: fit-content;
  min-width: 150px;
  height: 2.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: var(--form-button-font);
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}
.forgotPassword-button {
  width: 234px;
}
.resetPassword-button {
  width: 170px;
}
.button-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-bg {
  width: 480px;
  background-color: var(--white-color);
  padding: 4rem 3rem 4rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.form-link-text {
  max-width: 480px;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  /* position: absolute; */
  /* bottom: -60px; */
  /* left: 25%; */
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: var(--form-guide-text-font);
  color: var(--link-text-color);
}
.forgotPassword-helperText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: var(--forgotPassword-helperText-font);
  line-height: 15px;
  text-align: center;
  color: var(--forgotPassword-helperText);
}
.forgotPassword-form-bg {
  width: 480px;
  background-color: var(--white-color);
  padding: 4rem 3rem 4rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
/* -------------------------- */
.modal-header,
.dining-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #e2e8f0; */
  border-radius: 20px 20px 0px 0px;
  padding-left: 33px;
  padding-right: 33px;
}
.dining-modal-header {
  background: #ffffff;
  min-height: 3.5rem;
  height: fit-content;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  padding-left: 15px;
  padding-right: 15px;
}
.modal-heading {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #09101d;
}
.modal-content {
  max-width: 430px;
}
.modal-submit-button-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.dining-modal-div .modal-submit-button-div {
  width: fit-content;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.dining-modal .modal-content {
  width: 1010px !important;
  max-width: 1010px;
}
.dining-modal.modal-dialog {
  display: flex;
  justify-content: center;
  /* text-align: center; */
}
.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}
.fade {
  background: rgba(8, 12, 203, 0.5);
  transition: opacity 0.15s linear;
  padding: 1rem;
}
.modal-cancel-button {
  background: #a0aec0 !important;
  border-radius: 50px;
  width: fit-content;
  min-width: 150px;
  height: 2.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-size: var(--form-button-font);
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}
.modal-div {
  padding: 21px 33px 33px;
}
.dining-modal-div {
  /* padding: 0 15px; */
  width: 100%;
  display: flex;
  position: relative;
}
.modal-left-div {
  display: flex;
  padding: 15px;
  flex-basis: 60%;
  border-right: 1px solid rgba(113, 128, 150, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.modal-right-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  padding: 15px;
  flex-basis: 40%;
  margin-bottom: 5rem;
}
.modal-right-date-div {
  background: #e2e8f0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
}
.modal-right-date-div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2d3748;
}
.modal-right-date-div-wrap {
  width: 40%;
}
/* .modal-right */
.modal-right-wrap {
  width: 100%;
}
.modal-button-group {
  display: flex;
  justify-content: center;
  gap: 21px;
}
.delete-modal-message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #718096;
  padding-bottom: 1rem;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
}
@media (min-width: 576px) {
  .dining-modal.modal-dialog {
    max-width: 1010px;
    margin: 1.75rem auto;
  }
}
@media screen and (max-width: 400px) {
  .modal-button-group {
    gap: 10px;
  }
  .form-button {
    min-width: 120px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-cancel-button {
    min-width: 120px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 768px) {
  .button-full {
    width: 100%;
  }
  .button-spaceBetween {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.toastify__fontSize {
  font-size: 0.9rem;
  text-align: center;
}
.activity-radio-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 1rem;
}
.activity-radio-btn-edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 1rem;
}
.activity-radio-btn-edit input[type='radio']:checked:after {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #080ccb;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.activity-radio-btn input[type='radio']:checked:after {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #080ccb;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.pointer {
  cursor: pointer;
}
.activity-delete-radio-div {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.activity-delete-radio-form {
  display: flex;
  justify-content: center;
}
