.selcetor-parent {
  display: flex;
  position: relative;
  cursor: pointer;
}

.show-lang-selected {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;

  color: #718096;
}
.selector-wrap {
  display: flex;
  align-items: center;
  gap: 5px;
}
.select-dropdown {
  flex-direction: column;
  position: absolute;
  top: 3.4rem;
  background-color: var(--white-color);
  align-items: flex-start;
  width: 5rem;
  padding: 1rem;
  line-height: 2.5rem;
  z-index: 1100;
  left: 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.drop-down-container {
  display: flex;
  flex-direction: column;
}
.language-text {
  color: var(--navLink-color);
  font-weight: 600;
  font-size: 14px;
}
.language-text {
  color: var(--navLink-color);
  font-weight: 600;
  font-size: 14px;
}
