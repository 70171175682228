.refreshLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: rgba(250, 250, 250, 1);
  /* padding-right: 4rem; */
}
.pageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: rgba(250, 250, 250, 0.5);
  padding-right: 4rem;
}
.loader {
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: -50px auto 0;
  position: relative;
  color: rgba(8, 12, 203, 0.5);
  box-sizing: border-box;
  animation: animloader 2s ease infinite;
}
.svg-calLoader {
  width: 200px;
  height: 200px;
  transform-origin: 115px 115px;
  animation: 1.4s linear infinite loader-spin;
}

.cal-loader__plane {
  fill: #080ccb;
}
.cal-loader__path {
  stroke: #ffbd59;
  animation: 1.4s ease-in-out infinite loader-path;
}

@keyframes loader-spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-path {
  0% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
  50% {
    stroke-dasharray: 0, 450, 10, 30, 10, 30, 10, 30, 10;
  }
  100% {
    stroke-dasharray: 0, 580, 0, 0, 0, 0, 0, 0, 0;
  }
}
.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  color: rgba(8, 12, 203, 0.5);
  position: absolute;
  left: 30px;
  top: 0;
  animation: animloader 2s 0.2s ease infinite;
}
.loader::before {
  animation-delay: 0.4s;
  left: 60px;
}

@keyframes animloader {
  0% {
    top: 0;
    color: rgba(8, 12, 203, 0.5);
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color: rgba(8, 12, 203, 0.5);
  }
}
