.guest-card {
  height: 120px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.guest-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.guest-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.guest-card-div{
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.guest-head h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #09101d;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 865px) {
  .guest-card-container {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .guest-card-wrap {
    justify-content: center;
  }
  .guest-head {
    justify-content: center;
  }
}
/* ---------------responsive code-------------------- */
.guest-parent-card-R{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1.5rem;
}
@media screen and (max-width: 1400px) {
  .guest-parent-card-R{
      grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 1020px) {
  .guest-parent-card-R{
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 670px) {
  .guest-parent-card-R{
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}