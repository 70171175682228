.dropdown-content {
  width: 200px;
  height: fit-content;
  background: white;
  z-index: 500;
  position: absolute;

  padding: 20px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.menu-file-titles {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #718096;
  width: 80px;
  overflow-x: hidden;
  white-space: nowrap;
  height: 60px;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.menu-file-titles::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #bebebe;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.menu-file-titles::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 6px;
}
.menu-file-titles::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.menu-file-wrap {
  display: flex;
  gap: 15px;
}
.menu-file-row {
  background-color: rgba(113, 128, 150, 0.15);
  width: 60px;
  height: 60px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.dropDown-container {
  overflow: auto;
  height: auto;
  max-height: 30vh;
}
