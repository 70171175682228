/* -----Auth form media queries----- */
@media screen and (max-width: 768px) {
  .blue-bg {
    flex-basis: 20%;
  }
  .login-bg {
    flex-basis: 80%;
  }
  .form-div {
    margin-left: -10vw;
  }
  .logo {
    width: 10rem;
  }
  .form-bg {
    width: 400px;
  }
  .form-link-text {
    max-width: 400px;
  }
}

@media screen and (max-width: 550px) {
  .form-bg {
    max-width: 350px;
    width: 100%;
    align-items: center;
    padding: 3rem 2rem 3rem;
  }
  .form-link-text {
    max-width: 350px;
  }
  .button-div {
    flex-direction: column;
    gap: 1.5rem;
  }
  .form-input,
  .textarea-input {
    /* text-align: center; */
  }
  .form-input::placeholder,
  .textarea-input::placeholder,
  .date-input::placeholder {
    /* text-align: center; */
  }
  .form-input:placeholder-shown {
    /* text-align: center; */
  }
  .logo {
    width: 8rem;
  }
  .link-text {
    bottom: -60px;
    left: 18%;
  }
  .form-button-flex-center {
    display: flex;
    justify-content: center;
  }
  .forgotPassword-form-bg {
    padding: 3rem 2rem 3rem;
    max-width: 350px;
    width: 100%;
    align-items: center;
  }
  .validation-error {
    text-align: center;
  }
}
@media screen and (max-width: 430px) {
  .form-bg {
    padding: 1.5rem 2rem 1.5rem;
  }
  .date-time-parent-div {
    flex-direction: column;
    gap: 0;
  }
  .date-time-div {
    flex-basis: 100%;
  }
  .date-input {
    max-width: 100%;
    min-width: 100%;
  }
  .date-input[type='time']:focus {
    width: 100%;
  }
  .date-input[type='date']:focus {
    width: 100%;
  }
  .restaurantImage-upload-parent {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .restaurantImage-upload {
    width: 100% !important;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}
@media screen and (max-width: 375px) {
  .forgotPassword-form-bg {
    padding: 3rem 1rem 3rem;
  }
}
@media screen and (max-width: 340px) {
  .form-link-text {
    flex-direction: column;
    align-items: center;
    margin-top: 0.75rem;
  }
}
