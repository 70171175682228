table {
    /* border: 1px solid #ccc; */
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table thead {
    background-color: #A0AEC0;
  }
  .table-head tr th{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #09101D;
  }
  .table-body tr td{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #718096;
    word-break: break-all;
  }
  table tbody{
    background-color: #ffffff;
  }
  table tr {
    border: 1px solid #F4F4F4;
  }
  
  table tr {
    /* border: 1px solid #ddd; */
    padding: 0.1rem;
  }
  
  table th,
  table td {
    padding: .5rem;
    text-align: left;
  } 
  
  table td[data-label='Image']{
      display: flex;
      justify-content: center;
      align-items: center;
  }

  table th {
    font-size: 1rem;
  }
  
  @media screen and (max-width: 1000px) {
    table {
      border: 0;
    }
    
    table td[data-label='Action']{
      display: flex;
      justify-content: space-between;
    }
  
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
      display: none;
    }
    
    table tr {
      display: block;
      /* margin-bottom: .625rem; */
    }
    
    table td {
      text-align: right;
      display: block;
    }
    
    table td::before {
      content: attr(data-label);
      float: left;
      display: flex;
      align-items: flex-start;
      font-weight: bold;
    }

    td[data-label='Image']::before{
        display: none;
    }

    table td[data-label='Image']{
        width: 100%;
    }
  }
 
