.noData {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333943;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 80vh;
}
.no-data-txt {
  font-family: 'Inter';

  width: 40%;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .no-data-txt {
    width: 80%;
  }
}
