
.first-graph {                                            
  width: 100%;
  height: 50vh;
  background-color: white;
  border-radius: 10px;

}
.graph-heading{
  display: flex;
  text-align: initial;
  padding-left: 20px;
  padding-top: 20px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  
  color: #718096;
}
.noDashboardData{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #080CCB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 40vh;
}
