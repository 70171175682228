/* ------------------------------------------------------------- */
.restaurant-card {
  display: flex;
  flex-direction: column;
  height: 270px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}
.showMoreIcon {
  position: absolute;
  right: 14px;
  top: 20px;
  z-index: 500;
  text-align: center;

  width: 20px !important;
  height: fit-content;
}
.restaurant-image-div,
.restaurant-noImage-div {
  height: 147px;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #bdcde3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.restaurant-image-div {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.restaurant-image-div .restaurant-image {
  mix-blend-mode: overlay;
}
.restaurant-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
}
.restaurant-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 12px;
}
.restaurant-name-div {
  height: 20px;
  overflow-y: scroll;
}
.restaurant-name-div::-webkit-scrollbar {
  display: none;
}
.restaurant-name-div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.restaurant-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #09101d;
  width: 100%;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.restaurant-time-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.restaurant-time {
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #718096;
}
.view-dining-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid #080ccb;
  height: 31px;
  padding: 8px 16px;
  width: 100%;
}
.view-dining-button-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #080ccb;
}
.restaurantDropdown-wrap {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 500;
  min-width: 115px;
}
.restaurant-view-more {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #080ccb;
  background: none;
  border: none;
}

/* Restaurant-form---------------------------------- */
.restaurantImage-upload-parent {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  width: 100%;
}
.restaurantImage-upload-parent .noImage-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a0aec0;
  width: 100%;
  word-break: break-all;
}
.restaurantImage-upload {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 8px 16px;
  background: #a0aec0;
  border-radius: 18px;
  width: 40%;
  min-width: 145px;
}
.restaurantImage-upload .text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.menu-parent-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}
.menu-heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.menu-addMenu {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #09101d;
}
.menu-addMore {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #080ccb;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.menu-image-parent-div {
  height: 100%;
  width: 100%;
  border-bottom: 1px solid rgba(113, 128, 150, 0.15);
  margin-bottom: 1rem;
}
.noMenuAdded {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #a0aec0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 1rem;
}
.menuImg-frame-parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  padding-bottom: 1.5rem;
}
.menuImg-frame {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  background-color: rgba(113, 128, 150, 0.15);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuImg-frame img {
  border-radius: 8px;
}
.menuPreviewClose {
  position: absolute;
  top: -11px;
  right: -7px;
  z-index: 500;
}
/* ------------------responsive code------------------- */
.restaurant-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16%, 1fr));
  gap: 1.5rem;
}

@media screen and (max-width: 1420px) {
  .restaurant-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
}
@media screen and (max-width: 1219px) {
  .restaurant-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 833px) {
  .restaurant-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 483px) {
  .restaurant-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
