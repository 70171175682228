.pdf-component-wrap {
  position: absolute;
  top: -11;
  right: 20px;
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightBlue;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: 10px;
}
.pdf-container {
  height: 100%;
  text-align: center;
  width: 100%;
}
