.background-white {
  background-color: #ffffff;
}
.profile-dropdown-container {
  position: relative;
}
.profile-dropdown {
  display: none;
  position: absolute;
  z-index: 1100;
  width: 276px;
  height: fit-content;
  top: 56px;
  right: 0;
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}
.profile-dropdown.active {
  display: block;
}
.main-facility-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.75rem;
}
.main-facility-details {
  display: flex;
  align-items: center;
  height: 75px;
  margin-left: 1rem;
}
.main-facility-details-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}
.sub-facility-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  height: 75px;
  background: #e2e8f0;
  width: 100%;
  padding-left: 1rem;
  position: relative;
}
.subFacility-scroll-div {
  height: auto;
  max-height: 50vh;
  /* max-height: 90px; */
  overflow-y: auto;
  overflow-x: hidden;
}
.subFacility-scroll-div::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #080ccb;
}
.border-bottom {
  content: '';
  border: 0.5px solid #c3cbcd33;
  position: absolute;
  left: 7.5%;
  bottom: 0;
  width: 85%;
}
.sub-facility-data {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-basis: 80%;
}
.sub-facility-image {
  /* background-size: cover; */
  object-fit: fill;
}
.main-facility-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #2d3748;
}
.main-facility-email {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #a0aec0;
}
.sub-facility-name {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #718096;
}
.sub-facility-location {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}
.logout,
.newFacility {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ff0000;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.newFacility {
  color: #2d3748;
  border-bottom: 0.5px solid #c3cbcd33;
}
.navBar-facilityProfileText {
  display: none;
  font-size: 1.1rem;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  padding-top: 1rem;
  padding-left: 2.4rem;
  margin-bottom: 0.8rem;
  width: 10rem;
  height: 2.7rem;
}
.qr-code-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 20px;
  padding-left: 4rem;
  padding-right: 4rem;
}
.qr-code-div span,
.qr-code span,
.qr-code p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #718096;
}
.qr-code p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.qr-code-underline {
  width: 85%;
  border: 0.5px solid #c3cbcd33;
  margin-top: 30px;
  margin-bottom: 20px;
}

/* add new facility form css */
.addFacilityForm {
  width: 100%;
  height: 610px;
}
.addFacilityForm .scrollable-area {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.addFacilityForm .scrollable-area::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  background-color: #080ccb;
}
.addFacilityForm-parent {
  height: 610px;
}

@media screen and (max-width: 1040px) {
  .profile-dropdown.active {
    display: none;
  }
  .profile-sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1100;
    width: 100%;
    max-width: 19.9rem;
    min-height: 100vh;
    background: var(--white-color);
  }
  .profile-sidebar.active {
    display: block;
  }
  .profile-dropdown {
    border-radius: 0;
  }
  .profile-closeIcon {
    position: fixed;
    right: 2rem;
    top: 0;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e8f0;
  }
  .navBar-facilityProfileText {
    display: block;
  }
  .profileText-bottom-shadow {
    margin-bottom: 1rem;
    width: 330px;
    border-bottom: 1px solid rgba(195, 203, 205, 0.6);
    box-shadow: 4px 2px 7px rgba(0, 0, 0, 0.35);
    transform: translateX(-3.5%);
  }
  .main-facility-div {
    margin-right: 0.75rem;
  }
  .main-facility-details {
    padding-left: 1.5rem;
    margin-top: -1rem;
  }
  .main-facility-details-qr {
    /* padding-left: 1.5rem; */
    margin-top: -0.5rem;
  }
  .sub-facility-details {
    padding-left: 2.3rem;
  }
  .logout,
  .newFacility {
    padding-left: 2.5rem;
  }
}
@media screen and (max-width: 580px) {
  .profile-sidebar {
    max-width: 18.2rem;
  }
  .profile-closeIcon {
    right: 1.1rem;
  }
  .profileText-bottom-shadow {
    /* margin-bottom: 1rem; */
    width: 300px;
    transform: translateX(-3%);
  }
  .navBar-facilityProfileText {
    margin-bottom: 0.6rem;
  }
  .navBar-facilityProfileText {
    padding-left: 1.8rem;
  }
  .main-facility-details {
    margin-left: 0.3rem;
  }
  .sub-facility-details {
    padding-left: 1.6rem;
  }
  .logout,
  .newFacility {
    padding-left: 1.75rem;
  }
}
.pagination-down-arrow {
  width: 40px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}
.pagination-down-arrow-container {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e8f0;
}
.pagination-down-arrow-container-category {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
