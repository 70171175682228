.user-page {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 6.5rem;
}
.userPage-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.userPage-head h2 {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #09101d;
}
.userPage-btn-search {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
.userPage-form {
  display: flex;
  align-items: center;
}
.userPage-search-btn {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  position: relative;
}
.searchButton {
  width: 40px;
  height: 40px;
  border: 1px solid #e2e8f0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #080ccb 0%, #9708cb 100%);
  position: absolute;
  top: 0;
  right: 0;
}
.searchClose-button {
  position: absolute;
  top: 35%;
  right: 20%;
}
.addUser-button {
  width: 131px;
  height: 40px;
  background: linear-gradient(90deg, #080ccb 0%, #9808cb 100%);
  border-radius: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchBar-input {
  width: 258px;
  height: 40px;
  background: #ffffff;
  border-radius: 50px;
}
.searchBar-input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #a0aec0;
}
.userPage-search-btn .btn {
  padding: 0;
}
/* ------------Card----------- */
.user-card-div {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}
.user-card-container {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}
.user-card {
  height: 110px;
  background: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  z-index: 500;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
}
.user-image-div {
  width: 70px;
  min-width: 70px;
  height: 70px;
  background: #bdcde3;
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.user-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 60px;
}
.user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
  width: calc(100% - 130px) !important;
}
.user-details-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 20px;
}
.user-name,
.user-role,
.user-email {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #718096;
  height: 20px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.user-email {
  word-break: break-all;
}
.user-invitation {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #080ccb;
  background: none;
  border: none;
}
.disabled-color {
  color: #718096 !important;
}
.user-name {
  color: #09101d;
  font-weight: 600;
}
.user-action-buttons {
  height: 110px;
  /* background: #BDCDE3; */
  background: #080ccb;
  display: flex;
  flex-direction: column;
  width: 45px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0px 15px 15px 0px;
  justify-content: space-evenly;
  align-items: center;
}
.image-input {
  height: 38px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
}
.users-imageUpload-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.75rem;
  border: 1px solid var(--formInput-border-color);
  border-radius: 50px;
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
@media screen and (max-width: 630px) {
  .userPage-head {
    flex-direction: column;
    gap: 1rem;
  }
  .user-card-div {
    justify-content: center;
  }
}
@media screen and (max-width: 580px) {
  .user-card-div {
    justify-content: center;
  }
  .user-page {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
@media screen and (max-width: 460px) {
  .userPage-btn-search {
    flex-direction: column;
  }
  .userPage-head {
    gap: 1rem;
  }
}
@media screen and (max-width: 435px) {
  .user-card-container {
    justify-content: center;
  }
  .user-image-div {
    width: 70px;
    height: 70px;
    margin-right: 13px;
  }
}
@media screen and (max-width: 400px) {
  .user-image-div {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-right: 10px;
  }
}
/* -----------------------responsive code----------------- */
.users-parent-card-R {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  gap: 1.5rem;
}
@media screen and (max-width: 1300px) {
  .users-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
}
@media screen and (max-width: 1000px) {
  .users-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
}
@media screen and (max-width: 650px) {
  .users-parent-card-R {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
}
