.disabled-feature-parent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.disabled-features-normal-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.normal-checkbox-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #a0aec0;
}

.toggle-checkbox-label {
  margin-right: 1rem;
}
.toggle-checkbox-label .form-check-input:checked {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.toggle-checkbox-label .form-check-input:focus {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: unset !important;
}
.toggle-checkbox-label .form-check-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #a0aec0;
}

/* Add facility form */
.addFacilityForm .disabled-feature-parent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.addFacilityForm .disabled-features-normal-checkbox {
  display: flex;
  flex-wrap: wrap;
}
.addFacilityForm .normal-checkbox-label {
  flex-basis: 47%;
}
.addFacilityForm .toggle-checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.addFacilityForm .form-check-label {
  padding-top: 0.3rem;
}
