.page-link {
  padding: 0.65rem 0.65rem;
}
.paginate {
  width: 100vw;
  background-color: #f0f1ff;
  min-height: fit-content;
  position: fixed;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  padding-top: 23px;
  padding-bottom: 3px;
}
.relative-paginate {
  width: 100%;
  min-height: fit-content;
  position: absolute;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  padding-top: 23px;
  padding-bottom: 3px;
}
.pageButton-bg {
  background-color: #e2e8f0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #718096;
  width: 40px;
  height: 40px;
}
.border-left-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.border-right-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.border-radius {
  border-radius: 5px;
}
.margin-left {
  margin-left: 1rem;
}
.margin-right {
  margin-right: 1rem;
}
.pageButton-active {
  background: #080ccb;
}
.page-item.active .page-link {
  background-color: #080ccb;
  border-color: #080ccb;
}
.paginate li {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
